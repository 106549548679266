#header,
#header.header-inner-pages {
    background-color: white;
}

.get-started-btn {
    color: white;
    background-color: #47b2e4 !important;
}

.get-started-btn:hover {
    color: #47b2e4 !important;
    background-color: white !important;
}

#header.header-scrolled {
    background-color: #f5f5f5;
    border-bottom: solid 1px #ddd;
}

    #header.header-scrolled .get-started-btn {
        /*color: #004b96;
        border-color: #004b96;*/
        background-color: white;
    }

        #header.header-scrolled .get-started-btn:hover {
            background-color: #47b2e4;
            color: white;
        }

nav.nav-menu ul li a,
nav.nav-menu ul li.active a {
    color: #004b96;
}


.mobile-nav-toggle i {
    color: #004b96;
}

.mobile-nav a {
    color: #37517e;
}

.section-title h2 {
    color: #37517e;
}

.why-us .content h3 {
    color: #004b96;
}

.skills .content h3 {
    color: #004b96;
}

.skills .progress .skill {
    color: #004b96;
}

.services .icon-box h4 a {
    color: #004b96;
}

.cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../images/cta-bg.jpg") fixed center center;
}

.team .member h4 {
    color: #004b96;
}

.team .member .social a i {
    color: #004b96;
}

.pricing h3 {
    color: #004b96;
}

.pricing h4 {
    color: #004b96;
}

.faq .faq-list a.collapsed {
    color: #004b96;
}

.contact .info h4 {
    color: #004b96;
}

.breadcrumbs h2 {
    color: #004b96;
}

#footer .footer-newsletter h4 {
    color: #004b96;
}

#footer .footer-top .footer-contact h3 {
    color: #004b96;
}

#footer .footer-top h4 {
    color: #004b96;
}

.img-thumbnail {
    background-color: #ddd;
}

.portfolio .portfolio-item .portfolio-info .preview-link {
    top: 4px;
}

.portfolio .portfolio-item .portfolio-info .details-link {
    top: 4px;
}

.faq .faq-list a.faq-inline {
    font-weight: normal;
    padding: 0;
    display: inline;
}

#footer .footer-top.footerbg {
    background: #f3f5fa;
}

@media (min-width: 576px) {
    .twocolumnflow-sm {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
}

@media (min-width: 768px) {
    .twocolumnflow-md {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
}

@media (max-width: 992px) {
    .get-started-btn {
        display: none;
    }
}

.why-us-img {
    background-image: url("../images/why-us.png");
}